<template>
    <div
        class="px-4 w-full dF fC f1 pb-4 hide-scrollbar"
        style="overflow-y: scroll"
    >
        <ReportTopbar
            :title="broadcast.name || '...'"
            :sub="'Subject: ' + broadcast.subject || ''"
            back="/email"
        />
        <div class="my-4">
            <a-tabs size="large" default-active-key="1" v-model="tabKey">
                <a-tab-pane key="1" tab="Overview">
                    <BroadcastOverview :broadcast="broadcastData" />
                </a-tab-pane>
                <a-tab-pane key="2" tab="Engagement">
                    <OpensClicks :broadcast="broadcastData" />
                </a-tab-pane>
                <a-tab-pane key="3" tab="Other Activities">
                    <a-tabs
                        default-active-key="a1"
                        tab-position="left"
                        @change="handleChangeTab"
                    >
                        <a-tab-pane key="a1" tab="Bounced">
                            <ActivityCard type="failed" />
                        </a-tab-pane>
                        <a-tab-pane key="a2" tab="Unsubscribes">
                            <ActivityCard type="unsubscribed" />
                        </a-tab-pane>
                        <a-tab-pane key="a3" tab="Complained">
                            <ActivityCard type="complained" />
                        </a-tab-pane>
                    </a-tabs>
                </a-tab-pane>
                <div slot="tabBarExtraContent" class="dF" style="gap: 20px">
                    <div class="dF" style="gap: 5px">
                        <span>Download:</span>
                        <a
                            @click.prevent="getCSV"
                            href="javascript:;"
                            class="text-primary"
                            >CSV</a
                        >
                    </div>
                </div>
            </a-tabs>
        </div>
    </div>
</template>

<script>
import ReportTopbar from "@/components/reports/ReportTopbar.vue";
import ActivityCard from "@/components/email/ActivityCard.vue";
import BroadcastOverview from "@/components/email/BroadcastOverview.vue";
import OpensClicks from "@/components/email/OpensClicks.vue";
import { getCookie, arrToObj, formatDate, setProp } from "bh-mod";

export default {
    components: {
        ReportTopbar,
        ActivityCard,
        BroadcastOverview,
        OpensClicks,
    },
    data: () => ({
        currentTab: "a1",
        broadcast: {},
        details: {},
        openClicks: [],
		tabKey: '1'
    }),
    computed: {
        broadcastData() {
            return {
                ...this.broadcast,
                openClicks: this.openClicks,
                ...this.details,
            };
        },
    },
    methods: {
        handleChangeTab(e) {
            this.currentTab = e;
        },

        getCSV() {
			const csvType = this.tabKey === '2' ? 'clickedcsv' : 'csv';
			this.$store.commit("LOAD", true);
            fetch(
                `https://hook.bildhive.${this.$tld}/events/${this.$route.params.id}/${csvType}`,
                {
                    headers: {
                        "Content-Type": "text/csv",
                        Authorization: `Bearer ${getCookie("__bhjt")}`,
                        "x-instance": getCookie("instance"),
						"x-subdomain": "email"
                    },
                    responseType: "blob",
                }
            )
                .then((response) => {
					if (response.status === 400) {
						this.$store.commit("LOAD", false);
						return new Error(
							"There was an error downloading your report"
						);
					} else {
						return response.blob();
					}
				})
                .then((blob) => {
					this.$store.commit("LOAD", false);
                    const a = window.document.createElement("a");
                    a.href = window.URL.createObjectURL(blob, {
                        type: "text/csv",
                    });
                    a.download = this.broadcast.name + ".csv";
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                })
				.catch(() => {
					this.$store.commit("LOAD", false);
					this.$message.error(
						"No click events found for this Broadcast."
					);
				});
        },
    },
    created() {
		this.$api
			.get("/broadcasts/:instance/" + this.$route.params.id)
			.then((res) => {
				this.broadcast = res.data;
			}).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			});
		this.$api
			.get("/broadcast-details/:instance/" + this.$route.params.id)
			.then((res) => {
				this.details = res.data;
			}).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			});
		this.$api
			.get(
				`/events/${this.$route.params.id}/extract?types=opened,clicked,delivered`
			)
			.then((res) => {
				this.openClicks = res.data;
			}).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			});
	},
};
</script>

<style>
</style>
